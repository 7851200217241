import { useModelWrapper } from '../../scripts/component.utility';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'JobCheckStepThree',
    props: {
        location: String,
        locationLabel: String,
        locationPlaceholder: String,
        subjects: String,
        subjectsLabel: String,
        subjectsPlaceholder: String,
    },
    emits: ['proceed'],
    setup(props, { emit }) {
        const locationValue = useModelWrapper(props, emit, 'location');
        const subjectsValue = useModelWrapper(props, emit, 'subjects');
        return { locationValue, subjectsValue };
    },
});
