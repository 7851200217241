import { defineComponent, ref } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
export default defineComponent({
    name: 'JobCheckStepThree',
    props: {
        email: String,
        emailLabel: String,
        phone: String,
        phoneLabel: String,
        dataProtection: Boolean,
        emailValid: Boolean,
        showErrors: Boolean,
    },
    emits: ['proceed', 'checkWithoutProceed'],
    setup(props, { emit }) {
        const validateEmail = ref(false);
        const emailValue = useModelWrapper(props, emit, 'email');
        const phoneValue = useModelWrapper(props, emit, 'phone');
        const dataProtectionValue = useModelWrapper(props, emit, 'dataProtection');
        return { validateEmail, emailValue, phoneValue, dataProtectionValue };
    },
});
