import { defineComponent } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
export default defineComponent({
    name: 'JobCheckStepOne',
    props: {
        firstNameLabel: String,
        firstName: String,
        lastNameLabel: String,
        lastName: String,
    },
    emits: ['proceed'],
    setup(props, { emit }) {
        const firstNameValue = useModelWrapper(props, emit, 'firstName');
        const lastNameValue = useModelWrapper(props, emit, 'lastName');
        return { firstNameValue, lastNameValue };
    },
});
