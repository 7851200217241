import { Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import { defineComponent } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
export default defineComponent({
    name: 'JobCheckStepTwo',
    components: { Swiper, SwiperSlide },
    props: { professions: Array, initialState: Number },
    emits: ['proceed'],
    setup(props, { emit }) {
        const slideState = useModelWrapper(props, emit, 'initialState');
        const options = useModelWrapper(props, emit, 'professions');
        const breakpoints = {
            556: { slidesPerView: 3.25, slidesPerGroup: 3 },
            768: { slidesPerView: 2.5, slidesPerGroup: 2 },
            1024: { slidesPerView: 3.5, slidesPerGroup: 3 },
            1440: { slidesPerView: 3, slidesPerGroup: 3 },
        };
        return {
            options,
            slideState,
            breakpoints,
            modules: [Pagination, Navigation, Scrollbar],
        };
    },
});
