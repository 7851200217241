export const sendFormData = async (endpoint, formData, professions, language) => {
    const body = new FormData();
    const formValue = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        professionalField: professions
            .filter((p) => p.value)
            .map((p) => p.name)
            .join(', '),
        locations: formData.location,
        subjects: formData.subjects,
        email: formData.email,
        linkedInProfile: formData.linkedIn,
        phone: formData.phone,
        xingProfile: formData.xing,
        githubProfile: formData.github,
    };
    body.append('user', new File([JSON.stringify(formValue)], 'user.txt', {
        type: 'application/json',
    }));
    if (language)
        body.append('language', language);
    if (formData.cv)
        body.append('uploadedCV', formData.cv, formData.cv.name);
    try {
        // In that case we did not receive a endpoint configuration from FS and wont try to request it
        if (!endpoint)
            return true;
        return await fetch(endpoint, {
            method: 'POST',
            body,
        })
            .then((response) => response.json())
            .then((json) => {
            if (json.status === 500) {
                return null;
            }
            return json;
        });
    }
    catch (e) {
        return null;
    }
};
