import { computed, defineComponent, ref } from 'vue';
import Checkmark from '../../assets/svg/checkmark.svg';
export default defineComponent({
    name: 'CircleProgressIndicator',
    components: { Checkmark },
    props: {
        step: { type: Number, required: true },
        maxSteps: { type: Number, required: true },
        showAdvanced: Boolean,
    },
    setup(props) {
        const realMax = ref(props.showAdvanced
            ? props.maxSteps - (props.showAdvanced ? 1 : 0)
            : props.maxSteps);
        const percent = computed(() => Math.min(props.step / realMax.value, 1));
        const cssLeft = computed(() => ({
            transform: `rotate(${Math.min(percent.value * 360, 180)}deg)`,
        }));
        const cssRight = computed(() => ({
            transform: `rotate(${Math.max(percent.value * 360 - 180, 0)}deg)`,
        }));
        const success = computed(() => props.step >= props.maxSteps);
        return { cssLeft, cssRight, success, Checkmark, realMax };
    },
});
