import { defineComponent, ref } from 'vue';
import Github from '../../assets/svg/github.svg';
import Info from '../../assets/svg/IconInfo.svg';
import LinkedIn from '../../assets/svg/linked-in.svg';
import Xing from '../../assets/svg/xing.svg';
import { useModelWrapper } from '../../scripts/component.utility';
import BaseIcon from '../atoms/BaseIcon.vue';
import DialogComponent from '../molecules/DialogComponent.vue';
export default defineComponent({
    name: 'JobCheckStepThree',
    components: { Xing, LinkedIn, Github, Info, BaseIcon, DialogComponent },
    props: {
        xing: String,
        xingLabel: String,
        linkedIn: String,
        linkedInLabel: String,
        github: String,
        githubLabel: String,
        showGithub: Boolean,
        cv: File,
        cvUploadLabel: String,
        cvMaxFilesize: Number,
    },
    emits: ['proceed'],
    setup(props, { emit }) {
        const showXingInfo = ref(false);
        const showLinkedInInfo = ref(false);
        const showCvInfo = ref(false);
        const xingValue = useModelWrapper(props, emit, 'xing');
        const linkedInValue = useModelWrapper(props, emit, 'linkedIn');
        const githubValue = useModelWrapper(props, emit, 'github');
        const cvValue = useModelWrapper(props, emit, 'cv');
        const file = ref(null);
        const uploadFile = () => {
            const cvFile = file.value?.files?.[0] || null;
            if (cvFile &&
                cvFile?.size > 1000 * 1000 * (props.cvMaxFilesize || 3.5)) {
                showCvInfo.value = true;
            }
            else {
                cvValue.value = cvFile;
            }
        };
        return {
            Github,
            Info,
            LinkedIn,
            Xing,
            cvValue,
            file,
            githubValue,
            linkedInValue,
            showLinkedInInfo,
            showXingInfo,
            showCvInfo,
            uploadFile,
            xingValue,
        };
    },
});
