import { computed, defineComponent } from 'vue';
import { useModelWrapper } from '../../scripts/component.utility';
import BaseButton from '../atoms/BaseButton.vue';
import BaseIcon from '../atoms/BaseIcon.vue';
export default defineComponent({
    name: 'JobCheckStepFooter',
    components: { BaseButton, BaseIcon },
    emits: ['proceed'],
    props: {
        backLabel: String,
        canProceed: Boolean,
        enableNextButton: Boolean,
        currentStep: Number,
        finalStepButtonText: String,
        isLoading: Boolean,
        nextButtonLabel: String,
        sendButtonLabel: String,
        showErrors: Boolean,
        skipLabel: String,
        homeUrl: String,
    },
    setup(props, { emit }) {
        const currentStepValue = useModelWrapper(props, emit, 'currentStep');
        const showErrorsValue = useModelWrapper(props, emit, 'showErrors');
        const canSkip = computed(() => currentStepValue.value === 3 || currentStepValue.value === 4);
        const back = () => {
            currentStepValue.value--;
            showErrorsValue.value = false;
        };
        const skip = () => {
            currentStepValue.value++;
            showErrorsValue.value = false;
        };
        const buttonText = computed(() => currentStepValue.value < 5
            ? props.nextButtonLabel
            : currentStepValue.value === 6
                ? props.finalStepButtonText
                : props.sendButtonLabel);
        const goToHome = () => {
            if (props.homeUrl)
                location.href = props.homeUrl;
        };
        return { currentStepValue, canSkip, back, skip, buttonText, goToHome };
    },
});
