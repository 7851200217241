import { computed, defineComponent, onUpdated, ref } from 'vue';
import { triggerConfetti } from '../../scripts/confetti.utility';
import CircleProgressIndicator from '../molecules/CircleProgressIndicator.vue';
export default defineComponent({
    name: 'JobCheckStepHeader',
    components: { CircleProgressIndicator },
    props: {
        contactImage: String,
        nameStepTitle: String,
        experienceStepTitle: String,
        interestStepTitle: String,
        cvStepTitle: String,
        optInStepTitle: String,
        finalStepTitle: String,
        currentStep: { type: Number, required: true },
        firstName: String,
        showErrors: Boolean,
        fieldError: String,
        nameError: String,
        requiredFieldError: String,
    },
    setup(props) {
        const canvas = ref(null);
        const stepTexts = computed(() => [
            props.nameStepTitle,
            (props.experienceStepTitle || '').replace('%s', props.firstName || ''),
            (props.interestStepTitle || '').replace('%s', props.firstName || ''),
            props.cvStepTitle,
            props.optInStepTitle,
            (props.finalStepTitle || '').replace('%s', props.firstName || ''),
        ]);
        onUpdated(() => triggerConfetti(canvas));
        return { stepTexts, canvas };
    },
});
