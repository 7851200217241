import { defineComponent } from 'vue';
import GreenArrows from '../../assets/svg/green-arrows.svg';
import BaseButton from '../atoms/BaseButton.vue';
export default defineComponent({
    name: 'JobCheckOpener',
    components: { GreenArrows, BaseButton },
    props: {
        exitButtonText: String,
        exitButtonHref: String,
        entryText: String,
        entryButtonText: String,
    },
    emits: ['proceed'],
    setup() {
        return { GreenArrows };
    },
});
