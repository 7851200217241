import { computed, defineComponent, ref } from 'vue';
import { sendFormData, } from '../../scripts/job-check-wizard.form';
import DialogComponent from '../molecules/DialogComponent.vue';
import JobCheckOpener from './JobCheckOpener.vue';
import JobCheckStepFinal from './JobCheckStepFinal.vue';
import JobCheckStepFive from './JobCheckStepFive.vue';
import JobCheckStepFooter from './JobCheckStepFooter.vue';
import JobCheckStepFour from './JobCheckStepFour.vue';
import JobCheckStepHeader from './JobCheckStepHeader.vue';
import JobCheckStepOne from './JobCheckStepOne.vue';
import JobCheckStepThree from './JobCheckStepThree.vue';
import JobCheckStepTwo from './JobCheckStepTwo.vue';
export default defineComponent({
    name: 'JobCheckWizard',
    components: {
        DialogComponent,
        JobCheckOpener,
        JobCheckStepFinal,
        JobCheckStepFive,
        JobCheckStepFooter,
        JobCheckStepFour,
        JobCheckStepHeader,
        JobCheckStepOne,
        JobCheckStepThree,
        JobCheckStepTwo,
    },
    props: {
        exitButtonText: String,
        exitButtonHref: String,
        entryText: String,
        entryButtonText: String,
        backLabel: String,
        skipLabel: String,
        contactImage: String,
        firstNameLabel: String,
        lastNameLabel: String,
        locationLabel: String,
        locationPlaceholder: String,
        subjectsLabel: String,
        subjectsPlaceholder: String,
        nameStepTitle: String,
        experienceStepTitle: String,
        interestStepTitle: String,
        cvStepTitle: String,
        optInStepTitle: String,
        professions: Array,
        xingLabel: String,
        linkedInLabel: String,
        githubLabel: String,
        cvUploadLabel: String,
        cvMaxFilesize: Number,
        emailLabel: String,
        phoneLabel: String,
        nextButtonLabel: String,
        sendButtonLabel: String,
        fieldError: String,
        nameError: String,
        requiredFieldError: String,
        finalStepTitle: String,
        finalStepInfoText: String,
        finalStepButtonText: String,
        registerEndpoint: String,
        currentLanguage: String,
        homeUrl: String,
    },
    setup(props) {
        const isLoading = ref(false);
        const showRegisterError = ref(false);
        const currentStep = ref(0);
        const showErrors = ref(false);
        const slideStepTwoState = ref(0);
        const formData = ref({
            firstName: '',
            lastName: '',
            location: '',
            subjects: '',
            xing: '',
            linkedIn: '',
            github: '',
            cv: undefined,
            email: '',
            phone: '',
            dataProtection: false,
        });
        const professionOptions = ref(props.professions?.map((p) => ({
            name: p,
            value: false,
        })) || []);
        const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const emailValid = computed(() => EMAIL_REGEXP.test(formData.value.email.trim() || ''));
        const canProceed = computed(() => {
            switch (currentStep.value) {
                case 1:
                    return !!formData.value.firstName.trim();
                case 2:
                    return professionOptions.value.some((v) => v.value);
                case 3:
                    return (!!formData.value.location.trim() ||
                        !!formData.value.subjects.trim());
                case 4:
                    return (!!formData.value.xing.trim() ||
                        !!formData.value.linkedIn.trim() ||
                        !!formData.value.github.trim() ||
                        !!formData.value.cv);
                case 5:
                    return (!!formData.value.email.trim() &&
                        !!formData.value.dataProtection &&
                        emailValid.value);
                default:
                    return true;
            }
        });
        const enableNextButton = computed(() => {
            if (!!formData.value.email.trim() && emailValid.value)
                return true;
            return false;
        });
        const showGithub = computed(() => professionOptions.value.some((v) => v.value &&
            [
                'DevOps',
                'Software',
                'Architect',
                'Developer',
                'Entwickl',
                'Programmier',
            ].some((a) => v.name
                .toLocaleLowerCase()
                .includes(a.toLocaleLowerCase()))));
        const proceed = async () => {
            if (canProceed.value && currentStep.value === 5) {
                isLoading.value = true;
                const result = await sendFormData(props.registerEndpoint, formData.value, professionOptions.value, props.currentLanguage);
                isLoading.value = false;
                return result
                    ? currentStep.value++
                    : (showRegisterError.value = true);
            }
            else if (canProceed.value) {
                currentStep.value++;
                showErrors.value = false;
                return;
            }
            showErrors.value = true;
        };
        const checkWithoutProceed = () => {
            if (!canProceed.value) {
                showErrors.value = true;
            }
        };
        return {
            canProceed,
            checkWithoutProceed,
            enableNextButton,
            currentStep,
            emailValid,
            formData,
            isLoading,
            proceed,
            professionOptions,
            showErrors,
            showGithub,
            showRegisterError,
            slideStepTwoState,
        };
    },
});
